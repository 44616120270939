import type { App } from 'vue'
import { createPinia } from 'pinia'
import { setupWechatJsApi } from '../utils/wechat/jsapi.ts'

const pinia = createPinia()

export function setupStore(app: App<Element>) {
  app.use(pinia)
  setupWechatJsApi(app)
}

export default pinia
