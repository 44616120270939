import { ftLoginIdName } from '/@/config'
import { useUserStore } from '/@/store/modules/user.ts'
import { SITE_URL } from '../common'
import { isWechatBrowser } from '../common/device.ts'

export function getFtLoginId(): string | undefined {
  return localStorage.getItem(ftLoginIdName) || undefined
}

export function setFtLoginId(ft_login_id: string) {
  localStorage.setItem(ftLoginIdName, ft_login_id)
}

export async function setupUserLogin(app: any) {
  const user = await useUserStore()
    .getUserInfo()
    .catch(() => undefined)
  if (!user && isWechatBrowser()) redirect_to_login()
}

export function redirect_to_login() {
  if (/login/i.test(window.location.href)) {
    return
  }
  const callback = `${window.location.origin}/login?redirect=${encodeURIComponent(window.location.href)}`
  window.location.href = `https://${SITE_URL}/Api24/WechatMPUser/redirect_to_wechat_login?callback=${encodeURIComponent(callback)}`
}
