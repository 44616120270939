<script setup lang="ts">
let vh = window.innerHeight * 0.01
function adapt_vh() {
  vh = window.innerHeight * 0.01
}

window.addEventListener('resize', adapt_vh)
adapt_vh()
</script>

<template>
  <div class="ccgc-root-container" :style="{ '--vh': `${vh}px` }">
    <router-view />
  </div>
</template>

<style scoped lang="scss">
body {
  .ccgc-root-container {
    --100vh: calc(var(--vh, 1vh) * 100);
    width: 100vw;
    height: 100vh;
  }
}
</style>
