<script lang="ts">
import { defineComponent } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useUserStore } from '/@/store/modules/user.ts'
import { DEFAULT_AVATAR } from '/@/utils/common/assets-images.ts'
import { HomeIcon, CompassIcon, StarIcon, GridViewIcon } from 'tdesign-icons-vue-next'
import { gp } from '/@/plugins/gp'
import { CcActionSheetItem } from '/@/components/cc-action-sheet/index.vue'
import { openXwgBrowserFtWechatMiniProgram, redirectToOpenFtWechatMiniProgram } from '/@/utils/wechat'
import { isXwgBrowser } from '/@/utils/common/device.ts'
import * as qs from 'qs'
import { useResourcePackageDisplayModeStore } from '/@/store/modules/resource/resource-package-display-mode.ts'

export default defineComponent({
  name: 'ResourceSquareLayout',
  components: { HomeIcon, CompassIcon, StarIcon, GridViewIcon },
  data() {
    const router = useRouter()
    const route = useRoute()
    const userStore = useUserStore()
    const resourcePackageDisplayModeStore = useResourcePackageDisplayModeStore()
    return {
      resourcePackageDisplayModeStore,
      router,
      route,
      userStore,
      DEFAULT_AVATAR,
      search_keyword: route.query.keyword,
      sender_suid: route.query.suid,
    }
  },
  computed: {
    recommend_route() {
      return { name: 'ResourceSquareRecommendIndex', query: { category: this.route?.query?.category } }
    },
    latest_route() {
      return { name: 'ResourceSquareLatestIndex', query: { category: this.route?.query?.category } }
    },
    collect_route() {
      return { name: 'ResourceSquareCollectIndex', query: { category: this.route?.query?.category } }
    },
  },
  methods: {
    handleClick() {},
    onSearch(event: any) {
      const { value } = event
      if (!value) {
        this.router.push({
          name: 'ResourceSquareRecommendIndex',
        })
        return
      }
      this.search_keyword = value
      this.router.push({
        name: 'ResourceSquareSearchIndex',
        query: {
          keyword: value,
        },
      })
      this.search_keyword = ''
    },
    async onClickMore() {
      const items: CcActionSheetItem[] = [...this.resourcePackageDisplayModeStore.getChangeActionSheets()]

      const link_list: Array<{
        name: string
        page: string
        query?: any
      }> = [
        { name: '流量管理', page: '/pages/user/traffic/index/index' },
        { name: '如何在这里发布您的资源包', page: '/pages/public/view', query: { id: 's4ok' } },
        { name: 'CC空间介绍', page: '/pages/public/view', query: { id: 'w4g4' } },
        { name: '关于资源包', page: '/pages/public/view', query: { id: '040k' } },
      ]
      for (const link of link_list) {
        items.push({
          label: link.name,
          callback() {
            let query = { name: encodeURIComponent(link.name) }
            if (typeof link.query === 'object') {
              query = Object.assign(query, link.query)
            }
            const query_string = qs.stringify(query)
            if (isXwgBrowser()) {
              openXwgBrowserFtWechatMiniProgram(link.page, query_string)
            } else {
              redirectToOpenFtWechatMiniProgram(link.page, query_string)
            }
          },
        })
      }

      gp.$showActionSheet(items)
    },
    get_main_container() {
      return document.getElementById('resource-square-layout-container-main')
    },
  },
})
</script>

<template>
  <div class="resource-square-layout-container-root">
    <div class="resource-square-layout-container-wrapper">
      <div class="resource-square-layout-container" :class="{ 'hide-bottom-tab-bar': route.meta?.hideBottomTabBar }">
        <div class="resource-square-layout-container-header">
          <t-navbar :fixed="false" class="resource-square-layout-navbar">
            <template #left>
              <router-link v-if="route.name !== 'ResourceSquareRecommendIndex'" :to="{ name: 'ResourceSquareRecommendIndex' }">
                <t-icon name="home" size="32px" />
              </router-link>
              <t-search
                class="resource-square-layout-navbar-search"
                v-if="route.name !== 'ResourceSquareSearchIndex'"
                v-model="search_keyword"
                placeholder="搜索资源"
                shape="round"
                :on-submit="onSearch"
              />
              <span v-else-if="sender_suid">TA的所有发布</span>
              <span v-else>搜索资源</span>
            </template>
            <template #right>
              <span class="ft-user-avatar" v-if="userStore.union_id">
                <t-avatar size="32px" alt="未名" :image="userStore.avatar" />
              </span>
              <span class="ft-user-avatar" v-else>
                <t-avatar size="32px" alt="登陆" :image="DEFAULT_AVATAR" />
              </span>
            </template>
          </t-navbar>
          <t-divider style="margin-top: 5px" />
        </div>
        <div class="resource-square-layout-container-main" id="resource-square-layout-container-main">
          <router-view />
          <t-back-top :container="get_main_container" />
        </div>
        <div class="resource-square-layout-container-footer" v-if="!route.meta?.hideBottomTabBar">
          <t-grid :column="4" class="grid-demo">
            <router-link :to="recommend_route">
              <t-grid-item text="推荐">
                <template #image>
                  <home-icon />
                </template>
              </t-grid-item>
            </router-link>
            <router-link :to="latest_route">
              <t-grid-item text="最新">
                <template #image>
                  <compass-icon />
                </template>
              </t-grid-item>
            </router-link>
            <router-link :to="collect_route">
              <t-grid-item text="收藏">
                <template #image>
                  <star-icon />
                </template>
              </t-grid-item>
            </router-link>
            <t-grid-item text="更多" @click="onClickMore" style="cursor: pointer">
              <template #image>
                <grid-view-icon />
              </template>
            </t-grid-item>
          </t-grid>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
@media screen and (max-width: 500px) {
  .resource-square-layout-container-root {
    width: 100vw;
    height: 100vh;
    background: none !important;

    .resource-square-layout-container-wrapper {
      .resource-square-layout-container {
        background-color: white !important;
        width: 100vw !important;
        height: 100vh !important;
        position: absolute !important;
        margin: 0 !important;
        border-radius: 0 !important;
      }
    }
  }
}
</style>
<style scoped lang="scss">
.resource-square-layout-container-root {
  width: 100vw;
  height: 100vh;
  background: url('/images/bg.jpg') no-repeat center center / cover;
  position: absolute;

  .resource-square-layout-container-wrapper {
    background-color: #ffffff4d;
    width: 100%;
    height: 100%;
    position: absolute;

    .resource-square-layout-container.hide-bottom-tab-bar {
      .resource-square-layout-container-main {
        max-height: calc(100vh - 80px);
      }
    }

    .resource-square-layout-container {
      --resource-square-layout-container-outer-padding: 20px;
      width: 100%;
      max-width: 500px;
      overflow: hidden;
      background-color: white;
      margin: var(--resource-square-layout-container-outer-padding) auto;
      height: calc(100vh - (var(--resource-square-layout-container-outer-padding) * 2));
      border-radius: 5px;
      position: relative;

      &-header {
        height: auto;
        padding-top: 10px;
        font-size: 20px;
        color: var(--secondary-text-color);

        a {
          color: var(--secondary-text-color);
          &:hover {
            color: var(--primary-color);
          }
        }

        .t-icon {
          margin-right: 15px;
        }

        .resource-square-layout-navbar {
          --td-navbar-color: var(--primary-text-color);
          .ft-user-avatar {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            color: var(--secondary-text-color);
          }

          .resource-square-layout-navbar-search {
            --td-search-height: 32px;
            --td-search-font-size: 13px;
            :deep() {
              .t-icon {
                font-size: 18px !important;
              }
            }
          }
        }
      }

      &-main {
        height: auto;
        overflow: auto;
        padding-left: 15px;
        padding-right: 15px;
        padding-bottom: 15px;
        max-height: calc(100vh - 160px - calc(100vh - var(--100vh, 100vh)));

        :deep() {
          .resource-category-header-layout {
            margin-bottom: 15px;
          }
        }
      }

      &-footer {
        height: auto;
        position: absolute;
        bottom: 0;
        width: 100%;
        border-top: 1px solid var(--td-divider-color);
        padding-bottom: calc(100vh - var(--100vh, 100vh));

        background-color: #f2f2f2;
        --td-grid-item-bg-color: #f2f2f2;
        --td-grid-item-image-bg-color: #f2f2f2;
        --td-grid-item-text-padding-top: 0;
        padding-top: 3px;

        .t-grid-item {
          padding-top: 0;
        }
        .router-link-active {
          .t-grid-item {
            cursor: pointer;
            --td-grid-item-text-color: var(--primary-color);
            .t-icon {
              color: var(--primary-color);
            }
          }
        }

        .t-icon {
          width: 25px;
          height: 25px;
          color: var(--secondary-text-color);
        }
      }
    }
  }
}
</style>
