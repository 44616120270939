export const STORAGE_NAME_DEVICE_ID = 'device_id'

export function isWechatBrowser() {
  return /micromessenger/i.test(navigator.userAgent)
}

export function isXwgBrowser() {
  return /XWG/i.test(navigator.userAgent)
}

export function isPhone() {
  return /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)
}

export function isIos() {
  return /IOS/i.test(navigator.userAgent)
}

export function getDeviceId() {
  const deviceId = localStorage.getItem(STORAGE_NAME_DEVICE_ID)
  if (deviceId && !deviceId.startsWith('ANONYMOUS-')) return deviceId

  const seed = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
  let value = ''
  for (let i = 0; i < 20; i++) {
    value += seed[Math.floor(Math.random() * seed.length)]
  }
  localStorage.setItem(STORAGE_NAME_DEVICE_ID, value)
  return value
}

export async function sendXwgBrowserOpenMiniProgramEvent(config: { appid: string; username: string; path: string; type: number }) {
  if (window.xwg) {
    window.xwg.openMiniProgram(config.appid, config.username, config.path, config.type)
    return true
  }

  if (window?.webkit?.messageHandlers?.openMiniProgram) {
    window.webkit.messageHandlers.openMiniProgram.postMessage(JSON.stringify({ ...config, userName: config.username }))
    return true
  }

  return false
}
