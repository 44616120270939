export const JSAPI_UPDATE_APP_MESSAGE_SHARE_DATA = 'updateAppMessageShareData'

export const JSAPI_UPDATE_TIMELINE_SHARE_DATA = 'updateTimelineShareData'

export const JSAPI_ON_MENU_SHARE_WEIBO = 'onMenuShareWeibo'

export const JSAPI_ON_MENU_SHARE_QZONE = 'onMenuShareQZone'

export const JSAPI_START_RECORD = 'startRecord'

export const JSAPI_STOP_RECORD = 'stopRecord'

export const JSAPI_ON_VOICE_RECORD_END = 'onVoiceRecordEnd'

export const JSAPI_PLAY_VOICE = 'playVoice'

export const JSAPI_PAUSE_VOICE = 'pauseVoice'

export const JSAPI_STOP_VOICE = 'stopVoice'

export const JSAPI_ON_VOICE_PLAY_END = 'onVoicePlayEnd'

export const JSAPI_UPLOAD_VOICE = 'uploadVoice'

export const JSAPI_DOWNLOAD_VOICE = 'downloadVoice'

export const JSAPI_CHOOSE_IMAGE = 'chooseImage'

export const JSAPI_PREVIEW_IMAGE = 'previewImage'

export const JSAPI_UPLOAD_IMAGE = 'uploadImage'

export const JSAPI_DOWNLOAD_IMAGE = 'downloadImage'

export const JSAPI_TRANSLATE_VOICE = 'translateVoice'

export const JSAPI_GET_NETWORK_TYPE = 'getNetworkType'

export const JSAPI_OPEN_LOCATION = 'openLocation'

export const JSAPI_GET_LOCATION = 'getLocation'

export const JSAPI_HIDE_OPTION_MENU = 'hideOptionMenu'

export const JSAPI_SHOW_OPTION_MENU = 'showOptionMenu'

export const JSAPI_HIDE_MENU_ITEMS = 'hideMenuItems'

export const JSAPI_SHOW_MENU_ITEMS = 'showMenuItems'

export const JSAPI_HIDE_ALL_NON_BASE_MENU_ITEM = 'hideAllNonBaseMenuItem'

export const JSAPI_SHOW_ALL_NON_BASE_MENU_ITEM = 'showAllNonBaseMenuItem'

export const JSAPI_CLOSE_WINDOW = 'closeWindow'

export const JSAPI_SCAN_QRCODE = 'scanQRCode'

export const JSAPI_OPEN_PRODUCT_SPECIFIC_VIEW = 'openProductSpecificView'

export const JSAPI_ADD_CARD = 'addCard'

export const JSAPI_CHOOSE_CARD = 'chooseCard'

export const JSAPI_OPEN_CARD = 'openCard'
