import request from '/@/utils/common/request.ts'

export async function getJsApiConfig(url?: string) {
  if (!url) url = window.location.href.split('#')[0]
  return request({
    url: '/Api24/WechatMPUser/get_wxjs_config',
    method: 'post',
    disableShowError: true,
    data: {
      url,
    },
  })
}

export async function getLoginUserInfo() {
  return request({
    url: '/Api24/WechatMPUser/get_info',
    method: 'post',
  })
}

export async function loginFromState(state: string) {
  return request({
    url: '/Api24/WechatMPUser/login_from_state',
    method: 'post',
    data: {
      state,
    },
  })
}
