<script lang="ts">
import { defineComponent } from 'vue'
import type { ActionSheetItem } from 'tdesign-mobile-vue'

export type CcActionSheetItem = ActionSheetItem & {
  callback: Function
  name?: string
}

export type CcActionSheetConfig = Partial<{
  count: number
  theme: string
  align: string
  cancelText: string
  description: string
}>

export default defineComponent({
  name: 'CcActionSheet',
  data() {
    return {
      visible: false,
      items: [] as CcActionSheetItem[],
      count: 8,
      theme: 'list',
      align: 'center',
      cancelText: '',
      description: '',
    }
  },
  methods: {
    open(items: CcActionSheetItem[], config: CcActionSheetConfig = {}) {
      if (items.length < 1) return
      this.items = items.map((s) => {
        s.label = s.label || s.name || ''
        return s
      })
      this.count = config?.count || 8
      this.theme = config?.theme || 'list'
      this.align = config?.align || 'center'
      this.cancelText = config?.cancelText || ''
      this.description = config?.description || ''
      this.visible = true
    },
    onSelected(selected: CcActionSheetItem, index: number) {
      this.visible = false
      const callback = selected.callback || this.items[index].callback
      callback && callback()
    },
  },
})
</script>

<template>
  <t-action-sheet
    class="cc-action-sheet"
    :visible="visible"
    :items="items"
    :count="count"
    :theme="theme"
    :align="align"
    :cancelText="cancelText"
    :description="description"
    @selected="onSelected"
    @close="visible = false"
    @cancel="visible = false"
  />
</template>

<style scoped lang="scss"></style>
