import { getLoginUserInfo } from '/@/api/api24/user/wechat-mp-user.ts'
import { getFtLoginId } from '/@/utils/user/login.ts'
import { useRouter } from 'vue-router'
import { defineStore } from 'pinia'
import { loginFromState } from '../../api/api24/user/wechat-mp-user.ts'
import { setFtLoginId } from '../../utils/user/login.ts'

export const useUserStore = defineStore('user', {
  state() {
    const router = useRouter()
    return {
      router,
      token: '',
      username: '未名',
      union_id: '',
      avatar: '',
      ft_login_id: getFtLoginId(),
      user: undefined as any,
    }
  },
  getters: {
    getToken: (state) => state.token,
    getUsername: (state) => state.username,
    getAvatar: (state) => state.avatar,
  },
  actions: {
    async getUserInfo() {
      if (!this.ft_login_id) return undefined

      const { data } = await getLoginUserInfo()
      if (!data) return undefined

      this.user = data
      this.union_id = data.union_id
      this.avatar = data.avatar || data.faceimg

      return this.user
    },
    async loginFromState(state: string) {
      const { data } = await loginFromState(state)
      this.token = data.token
      this.username = data.username
      this.union_id = data.union_id
      this.avatar = data.avatar
      this.ft_login_id = data.login_id
      this.user = data
      setFtLoginId(data.login_id)
    },
  },
})
