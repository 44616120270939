export const RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_DEFAULT = 0
export const RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_SUCCINCT = 1
export const RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS = ['标准模式', '简洁模式']

const LAYOUT_DISPLAY_MODE_SETTING_KEY = 'resource-square-item-layout-display-mode'

export function getResourcePackageLayoutDisplayMode(): number {
  const displayMode = localStorage.getItem(LAYOUT_DISPLAY_MODE_SETTING_KEY)
  let displayModeValue = RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_DEFAULT
  if (displayMode) {
    try {
      displayModeValue = Number.parseInt(displayMode) || 0
      if (!RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS[displayModeValue]) {
        displayModeValue = RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_DEFAULT
      }
    } catch (ignore) {}
  }
  return displayModeValue
}

export function getResourcePackageLayoutDisplayModeLabel(value: number): string {
  return RESOURCE_PACKAGE_LAYOUT_DISPLAY_MODE_LABELS[value] || ''
}

export function setResourcePackageLayoutDisplayMode(value: number) {
  localStorage.setItem(LAYOUT_DISPLAY_MODE_SETTING_KEY, `${value}`)
}
