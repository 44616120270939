import { useWechatStore } from '/@/store/modules/wechat.ts'
import { getWx, updateWechatShare } from './index.ts'
import type { App } from 'vue'

let wechatStore: any = undefined
let wx: any = undefined

export function setupWechatJsApi(app: App<Element>) {
  const wechatStore = useWechatStore()
  getWx().then((value) => {
    wx = value
    wechatStore.initJsApiTicket()
    updateWechatShare('CC闪传资源广场')
  })
}
/**
 * 自定义“分享给朋友”及“分享到QQ”按钮的分享内容（1.4.0）
 */
export async function updateAppMessageShareData(
  config: Partial<{
    title: string
    desc: string
    link: string
    imgUrl: string
  }>
): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.updateAppMessageShareData({
        ...config,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 自定义“分享到朋友圈”及“分享到QQ空间”按钮的分享内容
 */
export async function updateTimelineShareData(
  config: Partial<{
    title: string
    link: string
    imgUrl: string
  }>
): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.updateTimelineShareData({
        ...config,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 拍照或从手机相册中选图接口
 */
export async function chooseImage(
  config: Partial<{
    count: number
    sizeType: Array<'original' | 'compressed'>
    sourceType: Array<'album' | 'camera'>
  }>
): Promise<{ localIds: string[] }> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.chooseImage({
        ...config,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 预览图片接口
 */
export async function previewImage(urls: string[], current: string): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.previewImage({
        urls,
        current: current || urls[0],
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 上传图片接口
 */
export async function uploadImage(localId: string, isShowProgressTips = 1): Promise<{ serverId: string }> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.uploadImage({
        localId,
        isShowProgressTips,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 下载图片接口
 */
export async function downloadImage(serverId: string, isShowProgressTips = 1): Promise<{ localId: string }> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.downloadImage({
        serverId,
        isShowProgressTips,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 获取本地图片接口
 */
export async function getLocalImgData(localId: string, compressionRatio = 1): Promise<{ localData: string }> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.getLocalImgData({
        localId,
        compressionRatio,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 使用微信内置地图查看位置接口
 */
export async function openLocation(config: {
  latitude: number /** 纬度，浮点数，范围为90 ~ -90 */
  longitude: number /** 经度，浮点数，范围为180 ~ -180。 */
  name: string /** 位置名 */
  address: string /** 地址详情说明 */
  scale: number /** 地图缩放级别,整型值,范围从1~28。默认为最大 */
  infoUrl: string /** 在查看位置界面底部显示的超链接,可点击跳转 */
}): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.openLocation(config)
    })
  })
}

/**
 * 获取地理位置接口
 */
export async function getLocation(type: 'wgs84' /** gps坐标 */ | 'gcj02' /** 火星坐标 */): Promise<{
  latitude: number // 纬度，浮点数，范围为90 ~ -90
  longitude: number // 经度，浮点数，范围为180 ~ -180。
  speed: number // 速度，以米/每秒计
  accuracy: number // 位置精度
}> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.getLocation({
        type,
        success: resolve,
        fail: reject,
      })
    })
  })
}

/**
 * 关闭当前网页窗口接口
 */
export async function closeWindow(): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.closeWindow()
    })
  })
}

/**
 * 批量隐藏功能按钮接口
 */
export async function hideMenuItems(menuList: string[]): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.hideMenuItems({ menuList })
    })
  })
}

/**
 * 批量显示功能按钮接口
 */
export async function showMenuItems(menuList: string[]): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.showMenuItems({ menuList })
    })
  })
}

/**
 * 隐藏所有非基础按钮接口
 */
export async function hideAllNonBaseMenuItem(): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.hideAllNonBaseMenuItem()
      resolve()
    })
  })
}

/**
 * 显示所有功能按钮接口
 */
export async function showAllNonBaseMenuItem(): Promise<void> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.showAllNonBaseMenuItem()
      resolve()
    })
  })
}

/**
 * 调起微信扫一扫接口
 */
export async function scanQRCode(
  needResult: 0 /*扫描结果由微信处理*/ | 1 /*直接返回扫描结果*/ = 0,
  scanType: Array<'qrCode' | 'barCode'> = ['qrCode']
): Promise<any> {
  const wx = await getWx()
  return new Promise((resolve, reject) => {
    wechatStore.onReady(function () {
      wx.scanQRCode({
        needResult,
        scanType,
        success: resolve,
        fail: reject,
      })
    })
  })
}
