export function getImageUrl(path: string): string {
  return new URL(path, import.meta.url).href
}

export const ASSETS_IMAGE_LOGO_H = getImageUrl('/@/assets/images/logo/logo-h.png')

export const ASSETS_ICON_ARROW_RIGHT = getImageUrl('/icons/arrow-right.png')
export const ASSETS_ICON_BLOCK = getImageUrl('/icons/block.png')
export const ASSETS_ICON_CHECK_BOX_DESELECT = getImageUrl('/icons/check-box-deselect.png')
export const ASSETS_ICON_CHECK_BOX_SELECTED = getImageUrl('/icons/check-box-selected.png')
export const ASSETS_ICON_CLOSE = getImageUrl('/icons/close.png')
export const ASSETS_ICON_COLLECT = getImageUrl('/icons/collect.png')
export const ASSETS_ICON_COMMENT = getImageUrl('/icons/comment.png')
export const ASSETS_ICON_COMMENT_LIST = getImageUrl('/icons/comment-list.png')
export const ASSETS_ICON_CUBE = getImageUrl('/icons/cube.svg')
export const ASSETS_ICON_CUBE_259FDE = getImageUrl('/icons/cube_259fde.svg')
export const ASSETS_ICON_CUBE_666666 = getImageUrl('/icons/cube_666666.svg')
export const ASSETS_ICON_DELETE = getImageUrl('/icons/delete.png')
export const ASSETS_ICON_DOCUMENT = getImageUrl('/icons/document.svg')
export const ASSETS_ICON_FILE_AUDIO = getImageUrl('/icons/file/audio.png')
export const ASSETS_ICON_FILE_EXCEL = getImageUrl('/icons/file/excel.png')
export const ASSETS_ICON_FILE_FILE = getImageUrl('/icons/file/file.png')
export const ASSETS_ICON_FILE_FOLDER = getImageUrl('/icons/file/folder.png')
export const ASSETS_ICON_FILE_PACKAGE = getImageUrl('/icons/package.png')
export const ASSETS_ICON_FILE_PDF = getImageUrl('/icons/file/pdf.png')
export const ASSETS_ICON_FILE_PHOTO = getImageUrl('/icons/file/photo.png')
export const ASSETS_ICON_FILE_PPT = getImageUrl('/icons/file/ppt.png')
export const ASSETS_ICON_FILE_TXT = getImageUrl('/icons/file/txt.png')
export const ASSETS_ICON_FILE_VIDEO = getImageUrl('/icons/file/video.png')
export const ASSETS_ICON_FILE_WORD = getImageUrl('/icons/file/word.png')
export const ASSETS_ICON_FILE_ZIP = getImageUrl('/icons/file/zip.png')
export const ASSETS_ICON_LIKE = getImageUrl('/icons/like.png')
export const ASSETS_ICON_LIKED = getImageUrl('/icons/liked.png')
export const ASSETS_ICON_LINK = getImageUrl('/icons/link.png')
export const ASSETS_ICON_MENU = getImageUrl('/icons/menu.png')
export const ASSETS_ICON_PAPER_PLANE = getImageUrl('/icons/paper-plane.svg')
export const ASSETS_ICON_RESOURCE_PACKAGE_LINK = getImageUrl('/icons/resource-package-link.png')
export const ASSETS_ICON_SHARE = getImageUrl('/icons/share.png')
export const ASSETS_ICON_VIEW = getImageUrl('/icons/view.png')
export const ASSETS_ICON_VIDEO_PLAY = getImageUrl('/icons/video-play.png')
export const ASSETS_ICON_VIEW_COUNT = getImageUrl('/icons/view-count.png')
export const ASSETS_ICON_SEND_PACKAGE_BUTTON = getImageUrl('/icons/send-package-button.png')
export const ASSETS_ICON_WECHAT_MINI_PROGRAM_FILL = getImageUrl('/icons/mini-program-fill.svg')

export const DEFAULT_AVATAR = 'https://cckj-avatar.cdn.xwg.cc/0'

export const MPCP_IMAGE_COMMUNITY_PACKAGE_DEFAULT_THUMB: string =
  'https://space-base.cdn.xwg.cc/default/mpcp/community/package/thumb/FhC7U--Yl7ApJd3AG3_YqFVwbDtU.png'
export const MPCP_IMAGE_RESOURCE_PACKAGE_DEFAULT_THUMB: string =
  'https://space-base.cdn.xwg.cc/default/thumb/package/FiamM3FZA9CSIrKGv8o86e1oxXCr.png'
export const MPCP_IMAGE_RESOURCE_PACKAGE_DEFAULT_THUMB_SQUARE: string =
  'https://space-base.cdn.xwg.cc/default/thumb/package/FqU4eJY-4-K2ThF0jo8qCJumNj9Z.png'
export const MPCP_IMAGE_AUDIO_DEFAULT_THUMB: string =
  'https://space-public.cdn.xwg.cc/default/thumb/thumb/audio/%20FhZ9EsYckq5GygYdJ1EPeOfw-n30'

export const MPCP_IMAGES_LOGO = 'https://space-base.cdn.xwg.cc/default/thumb/package/Fmbj-JqvBtgbGacOBR8zBQt4OYZ0.png'

export const MPCP_CC_COMMUNITY_PACKAGE_FORWARD_THUMB =
  'https://space-base.cdn.xwg.cc/default/mpcp/miniprogram/forward/FjzqiWSJhXiPvVnh5KgvKkaDSEMr.png'
export const MPCP_CC_COLLECT_PACKAGE_FORWARD_THUMB =
  'https://space-base.cdn.xwg.cc/default/mpcp/miniprogram/forward/FsUPJ6yZx2Db9xWBrpnm5qnOXzPe.png'
export const MPCP_CC_RESOURCE_PACKAGE_FORWARD_THUMB =
  'https://space-base.cdn.xwg.cc/default/mpcp/miniprogram/forward/FudOmnKVR2XeWNGO3RjWGTjPPs_Y.png'
export const MPCP_CC_TRANSFER_PACKAGE_FORWARD_THUMB =
  'https://space-base.cdn.xwg.cc/default/mpcp/miniprogram/forward/FvENlXCE4sFmzs4AjWA1j3R1kYg_.png'

export const MPCP_IMAGE_PUBLIC_PACKAGE_DEFAULT_THUMB =
  'https://space-base.cdn.xwg.cc/default/thumb/package/FkuEz2LMacOvKP5p6OCQ7zu4AuDa.png'
export const MPCP_IMAGE_PRIVATE_PACKAGE_DEFAULT_THUMB =
  'https://space-base.cdn.xwg.cc/default/thumb/package/FiaECWR_O8l3ShIcGzslCMTi0y5n.png'

export const MPCP_IMAGE_CLOUD_PACKAGE_PHOTO = 'https://space-base.cdn.xwg.cc/default/thumb/package/Fi1GAAu__78bOkb7VtNqIGF6ykJP.png'
export const MPCP_IMAGE_CLOUD_PACKAGE_VIDEO = 'https://space-base.cdn.xwg.cc/default/thumb/package/FtOEKtYWZeIws5uQ3UIxior58DXf.png'
export const MPCP_IMAGE_CLOUD_PACKAGE_WORK = 'https://space-base.cdn.xwg.cc/default/thumb/package/FjplOa_WtC8zR1mK5oC55L45VoyK.png'
export const MPCP_IMAGE_CLOUD_PACKAGE_FILE = 'https://space-base.cdn.xwg.cc/default/thumb/package/Fs8hhSr6kQIZ0BGfjDmB549SmWWQ.png'
export const MPCP_IMAGE_CLOUD_PACKAGE_LINK = 'https://space-base.cdn.xwg.cc/default/thumb/package/FssFR4VF1AHyArr7QFs7GHyDsV1M.png'
export const MPCP_IMAGE_SEARCH_STICKER = 'https://space-base.cdn.xwg.cc/default/thumb/package/FqU9GCu9CniZZ-71MPFSmoeDLXkW.png'

export const MPCP_HOME_SLOGAN_LOGO_URL = 'https://space-base.cdn.xwg.cc/default/mpcp/home/FnFVEodPbKntoLi5uIpM0kMvdD2v.png'
export const MPCP_HOME_RESOURCE_SQUARE_URL = 'https://space-base.cdn.xwg.cc/default/mpcp/home/FnLSflJM6PIvMiE9FelHiyT2Ikgs.png'
