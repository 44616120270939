import { head, toArray } from 'lodash-es'
import mitt from 'mitt'
import type { App, VNode } from 'vue'
import { createApp, h, inject } from 'vue'
import { loadingText, messageDuration } from '/@/config'
import CcActionSheet, { CcActionSheetConfig, CcActionSheetItem } from '../../components/cc-action-sheet/index.vue'
import { LoadingPlugin, Message, TNode, Toast, Button as TButton } from 'tdesign-mobile-vue'
import { TdLoadingProps } from 'tdesign-mobile-vue/es/loading/type'
import { TdMessageProps } from 'tdesign-mobile-vue/es/message/type'
import { TdDialogProps } from 'tdesign-mobile-vue/es/dialog/type'
import { ToastOptions } from 'tdesign-mobile-vue/es/toast/type'
import { v4 as uuid } from 'uuid'

export let gp: Record<
  | string
  | '$showActionSheet'
  | '$showConfirmDialog'
  | '$baseAlert'
  | '$baseConfirm'
  | '$baseLoading'
  | '$baseMessage'
  | '$baseToast'
  | '$pub'
  | '$sub'
  | '$unsub',
  any
>

export default {
  install(app: App<Element>) {
    /**
     * @description 全局加载层
     * @param {string} text 显示在加载图标下方的加载文案
     * @param config
     */
    const $baseLoading = (text = loadingText, config: TdLoadingProps = {}) => {
      return LoadingPlugin({
        text,
        fullscreen: true,
        ...config,
      })
    }

    /**
     * @description 全局Message
     * @param {string|VNode} message 消息文字
     * @param {'info' | 'success' | 'warning' | 'error' | 'closeAll'} type 主题
     */
    const $baseMessage = (message: TdMessageProps | string, type: 'info' | 'success' | 'warning' | 'error' | 'closeAll' = 'info') => {
      Message[type]({ duration: messageDuration, ...(typeof message == 'object' ? message : { content: message }) })
    }

    let $tDesignGlobalDialog: any = undefined
    /**
     * @description 全局Alert
     */
    const $baseAlert = (
      content: string | TNode,
      title = '提示',
      callback: Function | undefined = undefined,
      config: TdDialogProps = {}
    ) => {
      if (!$tDesignGlobalDialog) $tDesignGlobalDialog = inject('$dialog')

      $tDesignGlobalDialog.confirm({
        title,
        content,
        confirmBtn: '确认',
        onConfirm: callback,
        ...config,
      })
    }

    /**
     * @description 全局Confirm
     */
    const $baseConfirm = (content: string | VNode, title: string, onConfirm: any, onCancel: any, config: TdDialogProps = {}) => {
      if (!$tDesignGlobalDialog) $tDesignGlobalDialog = inject('$dialog')

      console.log('$tDesignGlobalDialog', $tDesignGlobalDialog)
      $tDesignGlobalDialog.confirm({
        title,
        content,
        cancelBtn: '确定',
        confirmBtn: '取消',
        onConfirm,
        onCancel,
        ...config,
      })
    }

    const _emitter = mitt()

    const $pub = (...args: any[]) => {
      _emitter.emit(head(args), args[1])
    }

    const $sub = (...args: any[]) => {
      Reflect.apply(_emitter.on, _emitter, toArray(args))
    }

    const $unsub = (...args: any[]) => {
      Reflect.apply(_emitter.off, _emitter, toArray(args))
    }

    let actionSheetRef: any = undefined

    const $showActionSheet = (items: CcActionSheetItem[], config: CcActionSheetConfig = {}) => {
      if (!actionSheetRef) {
        const actionSheetApp = createApp({
          render: () => h(CcActionSheet, { ref: 'actionSheet' }),
        })
        const vm = actionSheetApp.mount(document.createElement('div'))
        document.body.appendChild(vm.$el)
        if (vm.$refs.actionSheet) {
          actionSheetRef = vm.$refs.actionSheet
        }
      }
      if (actionSheetRef) actionSheetRef.open(items, config)
    }

    const $showConfirmDialog = async (config: TdDialogProps) => {
      return new Promise((resolve, reject) => {
        const confirmDialogApp = createApp({
          render: () =>
            h(TButton, {
              ref: 'tButtonRef',
              ...config,
              visible: true,
              onConfirm(context: { e: MouseEvent }) {
                resolve(context)
                if (config.onConfirm) config.onConfirm(context)
              },
              onCancel() {
                reject()
              },
              onClosed() {
                confirmDialogApp.unmount()
              },
            }),
          mounted() {},
        })
        const element = document.createElement('div')
        element.id = `t-button-${uuid()}`
        document.body.appendChild(element)
        const vm = confirmDialogApp.mount(element)
      })
    }

    const $baseToast = (
      message: string | ToastOptions,
      theme: 'loading' | 'success' | 'error' | undefined = undefined,
      duration: number = 2000,
      config: ToastOptions = {}
    ) => {
      if (typeof message === 'object') {
        Toast(message)
        return
      }

      Toast({
        duration,
        theme,
        direction: 'column',
        message,
        ...config,
      })
    }

    app.provide('$showActionSheet', $showActionSheet)
    app.provide('$showConfirmDialog', $showConfirmDialog)
    app.provide('$baseToast', $baseToast)

    app.provide('$baseAlert', $baseAlert)
    app.provide('$baseConfirm', $baseConfirm)
    app.provide('$baseLoading', $baseLoading)
    app.provide('$baseMessage', $baseMessage)
    app.provide('$pub', $pub)
    app.provide('$sub', $sub)
    app.provide('$unsub', $unsub)

    gp = {
      $showActionSheet,
      $baseAlert,
      $baseConfirm,
      $baseLoading,
      $baseMessage,
      $pub,
      $sub,
      $unsub,
      $baseToast,
      $showConfirmDialog,
    }
  },
}
