import { getJsApiConfig } from '/@/api/api24/user/wechat-mp-user.ts'
import {
  JSAPI_CHOOSE_IMAGE,
  JSAPI_CLOSE_WINDOW,
  JSAPI_DOWNLOAD_IMAGE,
  JSAPI_GET_NETWORK_TYPE,
  JSAPI_HIDE_ALL_NON_BASE_MENU_ITEM,
  JSAPI_HIDE_MENU_ITEMS,
  JSAPI_HIDE_OPTION_MENU,
  JSAPI_ON_MENU_SHARE_QZONE,
  JSAPI_ON_MENU_SHARE_WEIBO,
  JSAPI_OPEN_PRODUCT_SPECIFIC_VIEW,
  JSAPI_PREVIEW_IMAGE,
  JSAPI_SCAN_QRCODE,
  JSAPI_SHOW_ALL_NON_BASE_MENU_ITEM,
  JSAPI_SHOW_MENU_ITEMS,
  JSAPI_SHOW_OPTION_MENU,
  JSAPI_UPDATE_APP_MESSAGE_SHARE_DATA,
  JSAPI_UPDATE_TIMELINE_SHARE_DATA,
  JSAPI_UPLOAD_IMAGE,
} from '/@/utils/wechat/jsapi-list.ts'
import dayjs from 'dayjs'
import { defineStore } from 'pinia'
import { getWx } from '/@/utils/wechat'

let wx: any = undefined
getWx().then((value: any) => (wx = value))

export const useWechatStore = defineStore('wechat', {
  state() {
    return {
      jsapi_ticket_map: {} as Record<string, any>,
    }
  },
  actions: {
    async canUse(jsApiList: [] = []) {
      const wx = await getWx()
      return new Promise((resolve, reject) => {
        wx.checkJsApi({
          jsApiList,
          success: function (res) {
            resolve(res)
          },
          fail: function (res) {
            reject(res)
          },
        })
      })
    },
    async onReady(callback: Function) {
      const wx = await getWx()
      if (!wx) return
      await this.initJsApiTicket()
      wx.ready(callback)
    },
    async initJsApiTicket() {
      const wx = await getWx()
      const data = await this.getJsApiTicket()
      const config = {
        debug: false,
        appId: data.appid,
        timestamp: data.timestamp,
        nonceStr: data.nonceStr,
        signature: data.signature,
        jsApiList: [
          JSAPI_UPDATE_APP_MESSAGE_SHARE_DATA,
          JSAPI_UPDATE_TIMELINE_SHARE_DATA,
          JSAPI_ON_MENU_SHARE_WEIBO,
          JSAPI_ON_MENU_SHARE_QZONE,
          JSAPI_CHOOSE_IMAGE,
          JSAPI_PREVIEW_IMAGE,
          JSAPI_UPLOAD_IMAGE,
          JSAPI_DOWNLOAD_IMAGE,
          JSAPI_GET_NETWORK_TYPE,
          JSAPI_HIDE_OPTION_MENU,
          JSAPI_SHOW_OPTION_MENU,
          JSAPI_HIDE_MENU_ITEMS,
          JSAPI_SHOW_MENU_ITEMS,
          JSAPI_HIDE_ALL_NON_BASE_MENU_ITEM,
          JSAPI_SHOW_ALL_NON_BASE_MENU_ITEM,
          JSAPI_CLOSE_WINDOW,
          JSAPI_SCAN_QRCODE,
          JSAPI_OPEN_PRODUCT_SPECIFIC_VIEW,
        ],
        openTagList: ['wx-open-launch-weapp', 'wx-open-launch-app', 'wx-open-subscribe', 'wx-open-audio'],
      }
      await wx.config(config)
    },
    async getJsApiTicket() {
      const url = window.location.href.split('#')[0]

      if (this.jsapi_ticket_map[url]) {
        if (dayjs(this.jsapi_ticket_map[url].expire_at).add(5, 'second').isAfter(this.jsapi_ticket_map[url])) {
          return this.jsapi_ticket_map[url]
        }
      }

      const { data } = await getJsApiConfig(url)
      this.jsapi_ticket_map[data.url] = data

      return data
    },
  },
})
